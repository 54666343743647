import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          toast.success("Email sent successfully!");
          console.log("SUCCESS!");
          e.target.reset();
        },
        (error) => {
          toast.error(`Failed to send email`);
          console.log("FAILED...", error.text);
        }
      );
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page scrolls to the top when the component mounts
  }, []);

  return (
    <div className="contact">
      <main>
        <h1>Contact Us Today</h1>
        <p>
          Contact us today to schedule a consultation and let our team of
          branding wizards work their magic on your Upwork profile. We'll
          transform your profile into a captivating masterpiece that will leave
          potential clients spellbound. With our expertise in content and
          design, we'll help you stand out from the crowd and attract more
          customers. Don't miss out on the opportunity to skyrocket your revenue
          growth. Reach out to us now and let the magic begin!
        </p>

        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label>Name</label>
            <input
              type="text"
              name="user_name"
              required
              placeholder="Enter Your name"
            />
          </div>

          <div>
            <label>Email</label>
            <input
              type="email"
              name="user_email"
              required
              placeholder="Enter Your Email_id"
            />
          </div>

          <div>
            <label>Messege</label>
            <textarea
              type="text"
              required
              placeholder="Tell me about your query..."
              name="message"
            />
          </div>
          <button type="submit" value="Send">
            Send
          </button>
        </form>
        <ToastContainer />
      </main>
    </div>
  );
};

export default Contact;
