// import React from "react";
// import logoimg from "../assets/Branding.png";

// const Footer = () => {
//   return (
//     <footer>
//       <div>
//         {/* <h1>
//           Copyright © 2024 <br />
//           <strong>UpBranding Wizards</strong>
//         </h1>
//         <p>@All magical rights reserved</p> */}
//         <img src={logoimg} alt="website logo" />
//       </div>
//       <div>
//         <h5>follow us</h5>
//         <div>
//           <a href="mailto: akshaytirmalupwork@gmail.com">Gmail</a>
//           <a
//             href="https://www.linkedin.com/company/upbranding-wizards/?viewAsMember=true"
//             target={"blank"}
//           >
//             Linkedin
//           </a>
//           <a
//             href="https://www.facebook.com/people/UpBranding-Wizards/61561016910863/"
//             target={"blank"}
//           >
//             Facebook
//           </a>
//           <a href="https://www.instagram.com/" target={"blank"}>
//             Instagram
//           </a>
//         </div>
//       </div>
//       <div className="footerdown">
//         <p>Copyright © 2024 UpBranding Wizards @All magical rights reserved</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import logoimg from "../assets/Branding.png";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section logo-section">
          <img src={logoimg} alt="website logo" />
        </div>
        <div className="footer-section follow-section">
          <h5>Follow Us</h5>
          <div className="social-links">
            <a href="mailto: akshaytirmalupwork@gmail.com">
              <FaEnvelope /> Gmail
            </a>
            <a
              href="https://www.linkedin.com/company/upbranding-wizards/?viewAsMember=true"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <FaLinkedin /> LinkedIn
            </a>
            <a
              href="https://www.facebook.com/people/UpBranding-Wizards/61561016910863/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <FaFacebook /> Facebook
            </a>
            <a
              href="https://www.instagram.com/upbrandingwizards/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <FaInstagram /> Instagram
            </a>
          </div>
        </div>
      </div>
      <div className="footerdown">
        <p>
          <span>Copyright © 2024 UpBranding Wizards</span>
          <span> @All Magical Rights Reserved.</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
