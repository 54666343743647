// import React from "react";
// import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
// import logoimg from "../assets/Branding.png";

// const Navbar = () => {
//   return (
//     <nav>
//       <img src={logoimg} alt="website logo" />

//       <main>
//         <HashLink to={"/#home"}>Home</HashLink>
//         <Link to={"/contact"}>Contact</Link>
//         <HashLink to={"/#about"}>About</HashLink>
//         <HashLink to={"/#brands"}>FAQ's</HashLink>
//         <Link to={"/services"}>Services</Link>
//       </main>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FaBars, FaTimes } from "react-icons/fa";
import logoimg from "../assets/Branding.png";
// import "./Navbar.scss";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateHome = () => {
    window.location.href = "/";
    setIsMenuOpen(false);
  };

  return (
    <nav>
      <img src={logoimg} alt="website logo" onClick={navigateHome} />
      <div className="burger-menu" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      <main className={isMenuOpen ? "menu-open" : ""}>
        <HashLink to={"/#home"} onClick={() => setIsMenuOpen(false)}>
          Home
        </HashLink>
        <HashLink to={"/#about"} onClick={() => setIsMenuOpen(false)}>
          About
        </HashLink>
        <HashLink to={"/#services"} onClick={() => setIsMenuOpen(false)}>
          Services
        </HashLink>
        <Link to={"/contact"} onClick={() => setIsMenuOpen(false)}>
          Contact
        </Link>
        <HashLink to={"/#brands"} onClick={() => setIsMenuOpen(false)}>
          FAQ's
        </HashLink>
      </main>
    </nav>
  );
};

export default Navbar;
