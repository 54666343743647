import React, { useState } from "react";
import { Link } from "react-router-dom";
import vg from "../assets/Intro_img- OP1.webp";
import { FaRegStar } from "react-icons/fa6";
import img3 from "../assets/service 1 - OP3.webp";
import img4 from "../assets/service 2 - OP4.webp";
import img5 from "../assets/service 3 - OP1.webp";
import img6 from "../assets/service 4 - OP2.webp";
// import {
//   AiFillGoogleCircle,
//   AiFillAmazonCircle,
//   AiFillYoutube,
//   AiFillInstagram,
// } from "react-icons/ai";

const servicesData = [
  {
    img: img3,
    title: "Spellbinding Content Creation",
    text: "Looking to enhance your Upwork profile? Our spellbinding content creation service is just what you need. Our team of wizards will craft compelling and persuasive content that showcases your skills and expertise, making your profile irresistible to clients.",
  },
  {
    img: img4,
    title: "Magical Profile Rebranding",
    text: "Want to make a splash on Upwork? Our enchanting design service will help you create a visually stunning profile that will leave a lasting impression on potential clients. Our team of magicians will use their expertise to design a profile that reflects your unique brand and attracts more customers.",
  },
  {
    img: img5,
    title: "Enchanting Portfolio Creation",
    text: "Our team of enchanters will work their magic to create a captivating and visually stunning portfolio for your Upwork profile. With their expertise in design and content creation, they will ensure that your profile stands out from the crowd and leaves a lasting impression on potential clients.",
  },
  {
    img: img6,
    title: "Captivating Design",
    text: "Are you looking to completely transform your Upwork profile? Our rebranding service is perfect for you. Our team of sorcerers will give your profile a complete makeover, from the content to the design, to attract more customers and increase your revenue.",
  },
];

const Home = () => {
  const [isVisible, setIsVisible] = useState({
    result: false,
    content: false,
    magic: false,
    spells: false,
  });

  const toggleVisibility = (key) => {
    setIsVisible((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  return (
    <>
      <div className="home" id="home">
        <main>
          <h1>Unlock the Magic of Your Upwork Profile</h1>
          <p>Harness the Power of Enchanting Branding for Your Business</p>
        </main>
      </div>

      <div className="home2">
        <img src={vg} alt="Graphi cs" />
        <div>
          <p>
            Unlock the true potential of your freelance career with our expert
            Upwork Profile branding and content optimization services. Elevate
            your ratings, win more projects, and stand out in the competitive
            world of online freelancing.
            {/* Welcome to UpBranding Wizards, the leading agency for Upwork profile
            branding and rebranding services. Our team of magical experts is
            dedicated to giving your profile a content and design makeover that
            will attract more customers and drive significant revenue growth.
            With our enchanting skills, we will transform your profile into a
            captivating masterpiece that will leave a lasting impression on
            potential clients. Trust the wizards at UpBranding to elevate your
            Upwork presence to new magical heights. */}
          </p>
        </div>
      </div>

      <div className="home3" id="about">
        <div>
          <h1>About UpBranding Wizards</h1>
          <p>
            Welcome to UpBranding Wizards, the leading agency for Upwork profile
            branding and rebranding services. Our team of magical experts is
            dedicated to giving your profile a content and design makeover that
            will attract more customers and drive significant revenue growth.
            With our enchanting skills, we will transform your profile into a
            captivating masterpiece that will leave a lasting impression on
            potential clients. Trust the wizards at UpBranding to elevate your
            Upwork presence to new magical heights.
          </p>
        </div>
      </div>

      <div className="services" id="services">
        <h1>Our Services</h1>
        {servicesData.map((item, index) => (
          <div
            className={`service-item ${index % 2 === 0 ? "even" : "odd"}`}
            key={index}
          >
            <img src={item.img} alt={item.title} className="service-image" />
            <div className="text">
              <h1>{item.title}</h1>
              <hr />
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="home5" id="qualities">
        <div>
          <div>
            <FaRegStar />
          </div>
          <h1>Professional</h1>
          <p>
            Our team of enchanters is highly skilled in the art of Upwork
            profile branding. We have the power to transform your profile into a
            captivating masterpiece that will leave a lasting impression on
            potential clients. With our expertise, you can showcase your talents
            and abilities in a way that will make you stand out from the crowd.
          </p>
        </div>
        <div>
          <div>
            <FaRegStar />
          </div>
          <h1>Trustworthy</h1>
          <p>
            Trust is the foundation of any successful partnership, and we take
            it very seriously. Our clients trust us to deliver exceptional
            results and we always strive to exceed their expectations. When you
            choose UpBranding Wizards, you can have confidence in our ability to
            create a profile that will inspire trust and confidence in your
            potential clients.
          </p>
        </div>
        <div>
          <div>
            <FaRegStar />
          </div>
          <h1>Magical</h1>
          <p>
            Creativity is at the core of what we do. We believe that every
            profile should be a work of art, crafted with care and attention to
            detail. Our team of creative wizards will infuse your profile with a
            touch of magic, making it truly unique and captivating. Get ready to
            wow your clients with a profile that showcases your skills and
            personality like never before!
          </p>
        </div>
      </div>

      <div className="home4" id="brands">
        <div>
          <h1>FAQ's</h1>
          <article>
            {[
              {
                key: "result",
                question:
                  "Do you offer services for both freelancers and agencies?",

                answer:
                  "Yes, we provide content and design makeovers for both individual freelancers and agencies. Whether you're a solo freelancer looking to enhance your profile or an agency seeking to attract more clients, we can help you create a compelling and professional online presence.",
              },
              {
                key: "content",
                question: "What kind of makeover services do you provide?",
                answer:
                  "Definitely! We understand the importance of a well-crafted profile in attracting customers. By improving the content and design of your profile, we can help you increase your visibility and credibility, leading to more opportunities and revenue growth.",
              },
              {
                key: "magic",
                question: "How can you help me attract more customers?",
                answer:
                  "Absolutely! Our team of experts is skilled in giving a fresh and modern look to your profile, making it more appealing to potential customers. By revamping your profile, you'll be able to showcase your skills and expertise in a way that stands out from the competition.",
              },
              {
                key: "spells",
                question:
                  "Will improving my profile really lead to revenue growth?",
                answer:
                  "Certainly! Our goal is to help you optimize your profile to attract more customers and generate significant revenue growth. With our content and design makeover services, you'll be able to present yourself or your agency in the best possible light, making a lasting impression on potential clients.",
              },
            ].map((item, index) => (
              <div
                key={item.key}
                style={{ animationDelay: `${0.3 * (index + 1)}s` }}
                onClick={() => toggleVisibility(item.key)}
              >
                <div className="faq-header">
                  <h3>{item.question}</h3>
                  <button>{isVisible[item.key] ? "-" : "+"}</button>
                </div>
                {isVisible[item.key] && <p>{item.answer}</p>}
              </div>
            ))}
          </article>
        </div>
      </div>

      <div className="home6" id="contactus">
        <div>
          <h1>Contact Us Today</h1>
        </div>
        <div>
          <p>
            Contact us today to schedule a consultation with our team of magical
            branding experts. We can't wait to work our wizardry on your Upwork
            profile and help you achieve success beyond your wildest dreams!
          </p>
          <div>
            <Link to={"/contact"}>Get In Touch</Link>
            <i>Unlock the Magic of Success</i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
